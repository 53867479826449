import React, {useContext, useEffect} from 'react';
import {
    Link,
    makeStyles
} from '@material-ui/core';
import {useParams} from "react-router-dom";
import {ApiContext} from "../../contexts/ApiContext";
import Loader from "../../components/Loader";

const useStyles = makeStyles((theme) => ({
    AttachmentView: {
        height: '100%',
    }
}));

// TODO: Add Page
const AttachmentView = () => {
    const classes = useStyles();

    const key = useParams()['*'];
    const [url, setUrl] = React.useState();
    const api = useContext(ApiContext);
    if (!key) {
        console.warn("attachment key is not valid");
    }

    useEffect(() => {
        (async () => {
            const signedUrl = await api.storage.getSignedUrl(key);
            setUrl(signedUrl);
        })();
    }, )

    return url ? (
        <Link to={url} download target="_self">Download</Link>
    ) : (
        <Loader />
    );
};

export default AttachmentView;