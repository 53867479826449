import React from 'react';
import PropTypes from 'prop-types';
import {
    makeStyles,
    useTheme,
    Dialog,
    useMediaQuery, DialogTitle, DialogContent, DialogActions, Button, DialogContentText, alpha
} from '@material-ui/core';
import {useTranslation} from "react-i18next";
import { formSelector } from "../../state/forms/selectors";
import { useSelector } from "react-redux";

// TODO: Extract common dialog styles to dialog component
const useStyles = makeStyles((theme) => ({
    dialogContent: {
        paddingBottom: 0
    },
    dialogActions: {
        padding: theme.spacing(3)
    },
    deleteButton: {
        backgroundColor: alpha(theme.palette.error.main, 0.85),
        color: theme.palette.error.contrastText,
        '&:hover': {
            backgroundColor: theme.palette.error.main
        },
    }
}));

const DeleteFormConfirmationDialog = ({className, open, onConfirm, onClose, workspaceKey, formKey}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const form = useSelector(formSelector(workspaceKey, formKey));

    return (
        <Dialog
            id="delete-form-confirmation-dialog"
            className={className}
            fullScreen={fullScreen}
            open={open}
            onClose={onClose}
            aria-labelledby="delete-form-confirmation-dialog-title"
        >
            <DialogTitle id="delete-form-confirmation-dialog-title" className={classes.dialogTitle}>
                {t("formDeleteDialogTitle", {name: form?.name})}
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <DialogContentText variant="body2" color="textSecondary">
                    {t("formDeleteDialogSubtitle")}
                </DialogContentText>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                <Button autoFocus color="primary" variant="outlined" onClick={onClose}>
                    {t("Cancel")}
                </Button>
                <Button className={classes.deleteButton} autoFocus variant="contained" onClick={onConfirm}>
                    {t("delete")}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

DeleteFormConfirmationDialog.propTypes = {
    className: PropTypes.string,
    open: PropTypes.bool,
    workspaceKey: PropTypes.string,
    formKey: PropTypes.string,
    onConfirm: PropTypes.func,
    onClose: PropTypes.func
}

DeleteFormConfirmationDialog.defaultProps = {
    onConfirm: () => {},
    onClose: () => {}
};

export default DeleteFormConfirmationDialog;