import React, { useContext, useState } from 'react';
import {
    Box, makeStyles,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, Typography
} from '@material-ui/core';
import Button from "@material-ui/core/Button";
import { Skeleton } from "@material-ui/lab";
import { useSelector } from "react-redux";
import { currentUserSelector } from "../../../state/currentUser/selectors";
import { workspaceSelector } from "../../../state/workspaces/selectors";
import { ApiContext } from "../../../contexts/ApiContext";
import { stableSort } from "../../../utils/arrayUtils";
import IconButton from "@material-ui/core/IconButton";
import MoreVertOutlinedIcon from "@material-ui/icons/MoreVertOutlined";
import InvitationDialog from "./InvitationDialog";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import UserCard from "../../../components/UserCard";
import { invitationsSelector } from "../../../state/invitations/selectors";
import { activeMembersSelector } from "../../../state/members/selectors";
import { useParams } from "react-router-dom";
import { toWorkspaceKey } from "../../../utils/apiUtils";

const useStyles = makeStyles((theme) => ({
    header: {
        display: "flex",
        alignItems: "flex-start"
    },
    inviteButton: {
        marginTop: theme.spacing(0.5),
        flex: "0 0 auto"
    }
}));

const InvitationsSection = ({className, classes: propClasses}) => {
    const classes = useStyles();
    const api = useContext(ApiContext);
    const { t } = useTranslation();
    const {workspaceId} = useParams();
    const workspaceKey = toWorkspaceKey(workspaceId);
    const workspace = useSelector(workspaceSelector(workspaceKey));
    const currentUser = useSelector(currentUserSelector());
    const invitations = useSelector(invitationsSelector(workspaceKey));
    const members = useSelector(activeMembersSelector(workspaceKey));
    const [invitationDialogOpen, setInvitationDialogOpen] = useState(false);
    const [invitationMenuSource, setInvitationMenuSource] = useState();

    const handleSendInvitations = (invitations) => {
        for (const invitation of invitations) {
            if (invitation.email === currentUser.email || members.find(member => member.email === invitation.email)) {
                continue;
            }
            api.mutation.createInvitation(invitation.email, workspaceKey, invitation.role, currentUser.name, workspace.name, currentUser.locale);
        }
        setInvitationDialogOpen(false);
    }

    const handleInvitationMenuOpen = (event, invitationId) => {
        setInvitationMenuSource({invitationId, anchorEl: event.currentTarget});
    }

    const handleInvitationMenuClose = () => {
        setInvitationMenuSource(null);
    }

    const handleContextMenuDeleteClicked = (invitationId) => {
        api.mutation.deleteInvitation(invitationId);
        //dispatch(deleteInvitation(workspaceKey, invitationId));
        handleInvitationMenuClose();
    }

    const renderContextMenu = () => (
        <Menu
            anchorEl={invitationMenuSource?.anchorEl}
            anchorOrigin={{vertical: "bottom", horizontal: "center"}}
            getContentAnchorEl={null}
            id="invitation-menu"
            keepMounted
            transformOrigin={{vertical: "top", horizontal: "center" }}
            open={Boolean(invitationMenuSource)}
            onClose={handleInvitationMenuClose}
        >
            <MenuItem onClick={() => handleContextMenuDeleteClicked(invitationMenuSource.invitationId)}>{t("delete")}</MenuItem>
        </Menu>
    )

    const renderInvitations = () => {
        if (!invitations) {
            return (
                <>
                    <Skeleton />
                    <Skeleton />
                </>
            )
        } else if (invitations.length === 0) {
            return (
                <Typography variant="body2" color="secondary">
                    {t("No pending invitations")}
                </Typography>
            )
        } else {
            return (
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell className={propClasses.tableHeader}>
                                    {t("email")}
                                </TableCell>
                                <TableCell className={propClasses.tableHeader}>
                                    {t("Invited as")}
                                </TableCell>
                                <TableCell className={propClasses.tableHeader}>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {stableSort(invitations, 'asc', 'email')
                                .map((invitation) => {
                                    return (
                                        <TableRow key={invitation.id} >
                                            <TableCell component="th" scope="row">
                                                {<UserCard size="large" name={invitation.email} />}
                                            </TableCell>
                                            <TableCell>
                                                {t(invitation.role)}
                                            </TableCell>
                                            <TableCell align="right">
                                                <IconButton size="small" edge="end" onClick={(event => handleInvitationMenuOpen(event, invitation.id)) }>
                                                    <MoreVertOutlinedIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    )})
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            )
        }
    }

    return (
        <div className={className}>
            <div className={classes.header}>
                <Box flex="1 1 auto">
                    <Typography variant="h5" gutterBottom>
                        {t("Invitations")}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                        {t("People invited to your workspace that have not yet accepted the invitation")}
                    </Typography>
                </Box>
                <Button className={classes.inviteButton} variant="contained" color="primary" onClick={() => setInvitationDialogOpen(true)}>
                    {t("Invite member")}
                </Button>
            </div>

            <div className={propClasses.content}>
                { renderInvitations() }
            </div>
            <InvitationDialog open={invitationDialogOpen} onSendInvitations={handleSendInvitations} onClose={() => setInvitationDialogOpen(false)}/>
            {renderContextMenu()}
        </div>
    );
};

export default InvitationsSection;

InvitationsSection.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.shape({
        content: PropTypes.string,
        tableHeader: PropTypes.string
    })
}

InvitationsSection.defaultProps = {
    classes: {}
};