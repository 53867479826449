import {Storage} from 'aws-amplify';
import config from '../aws-exports';
import {ImageTools} from "../utils/ImageTools";
import {AvatarPictureSizes, ThumbnailPictureDimensions} from "../constants";
import {newUuid} from "../utils/idGenerator";
import {getExtension, isImage} from "../utils/fileUtils";
import {LargePictureSuffix, SmallPictureSuffix, ThumbnailPictureSuffix} from "../constants/storage";

const {
    aws_user_files_s3_bucket_region: region,
    aws_user_files_s3_bucket: bucket
} = config

export const currentVersion = "v1";

export default class StorageApi {
    getPublicUrl(key) {
        return key ? `https://${bucket}.s3.${region}.amazonaws.com/public/${key}` : null;
    }

    async getSignedUrl(key) {
        if (!key) {
            return null;
        }

        if (!key.startsWith("v1")) {
            return this.getPublicUrl(key);
        }

        return await Storage.get(key, { expires: 60 });
    }

    async downloadObject(key) {
        if (!key) {
            return null;
        }

        // TODO: Expiration was disabled, because it disabled caching, file were downloaded every time.
        // TODO: Cache blob urls instead of relying on StorageApi cache, then reenable expiration.
        return await Storage.get(key, { expires: 60, download: true, cacheControl: 'max-age=2419200' }); // 2419200 = four weeks
    }

    async getImageAttachmentUrl(key) {
        if (!key) {
            return null;
        }

        // All objects in v0 were uploaded before S3 was secured
        if (!key.startsWith("v1")) {
            return this.getPublicUrl(key);
        }

        if (this._isThumbnailPicture(key)) {
            return this.getSignedUrl(key.replace(ThumbnailPictureSuffix, ""));
        }

        return this.getSignedUrl(key);
    }

    getLargeAvatarPictureUrl(key) {
        if (!key) {
            return null;
        }

        if (this._isSmallPictureKey(key)) {
            return this.getPublicUrl(key.replace(SmallPictureSuffix, LargePictureSuffix));
        }

        if (this._isLargePictureKey(key)) {
            return this.getPublicUrl(key);
        }

        // Key can be neither large or small key if the avatar picture has been
        // uploaded before the small/large avatars have been implemented. In that
        // case, return url of the avatar from public folder.
        return this.getPublicUrl(key);
    }

    getSmallAvatarPictureUrl(key) {
        if (!key) {
            return null;
        }

        if (this._isSmallPictureKey(key)) {
            return this.getPublicUrl(key);
        }

        if (this._isLargePictureKey(key)) {
            return this.getPublicUrl(key.replace(LargePictureSuffix, SmallPictureSuffix));
        }

        // Key can be neither large or small key if the avatar picture has been
        // uploaded before the small/large avatars have been implemented. In that
        // case, return url of the avatar from public folder.
        return this.getPublicUrl(key);
    }

    async uploadDocument(file, progressCallback) {
        //// Simplified version of the uplAttachment:
        const key = `${currentVersion}/attachments/${newUuid()}/${file.name}`;
        return await this.uploadFile(file, key, progressCallback);
    }

    async uploadAttachment(file, progressCallback) {
        //const normalizedName = file.name.replace(/ /g,"_");
        const key = `${currentVersion}/attachments/${newUuid()}/${file.name}`;
        if (isImage(file.name)) {
            return await this._uploadImageAttachment(file, key, progressCallback);
        } else {
            return await this.uploadFile(file, key, progressCallback);
        }
    }

    async uploadFile(file, key, progressCallback) {
        try {
            await Storage.put(key, file, {
                contentType: file.type,
                progressCallback: (progress) => {progressCallback(progress.loaded / progress.total * 100)}
            });
        } catch (err) {
            console.warn('error: ', err)
        }

        return key;
    }

    async uploadProfilePicture(file, userId, progressCallback) {
        const pictureName = newUuid() + getExtension(file.name);
        return this._uploadAvatarPictures(file, `${currentVersion}/profile-pictures/${userId}/${pictureName}`, progressCallback);
    }

    async uploadWorkspacePicture(file, workspaceId, progressCallback) {
        const pictureName = newUuid() + getExtension(file.name);
        return this._uploadAvatarPictures(file, `${currentVersion}/workspace-pictures/${workspaceId}/${pictureName}`, progressCallback);
    }

    async deleteAttachment(key) {
        if (!key.startsWith("v1")) {
            // Delete avatar picture that have been uploaded before small/large picture
            // keys have been implemented. Such avatars were uploaded to "public" folder.
            await this._deleteObject(key);
            return;
        }

        let keysToRemove = [key];
        if (this._isThumbnailPicture(key)) {
            const imageKey = key.replace(ThumbnailPictureSuffix, "");
            keysToRemove = [...keysToRemove, imageKey]
        }

        try {
            for (const keyToRemove of keysToRemove) {
                await Storage.remove(keyToRemove);
            }
        } catch (err) {
            console.warn('error: ', err)
        }
    }

    async deleteAvatarPictures(key) {
        const isSmallKey = this._isSmallPictureKey(key);
        const isLargeKey = this._isLargePictureKey(key);
        if (!isSmallKey && !isLargeKey) {
            // Delete avatar picture that have been uploaded before small/large picture
            // keys have been implemented. Such avatars were uploaded to "public" folder.
            await this._deleteObject(key);
            return;
        }

        let smallKey = key;
        let largeKey = key;
        if (isSmallKey) {
            largeKey = key.replace(SmallPictureSuffix, LargePictureSuffix);
        } else if (isLargeKey) {
            smallKey = key.replace(LargePictureSuffix, SmallPictureSuffix);
        }

        try {
            // Remove small avatar
            await Storage.remove(smallKey);

            // Remove large avatar
            await Storage.remove(largeKey);
        } catch (err) {
            console.warn('error: ', err)
        }
    }

    // TODO: Upload svg avatars only once
    async _uploadAvatarPictures(file, key, progressCallback) {
        try {
            // Resize avatar picture to small size and upload it to S3
            const smallKey = this._addSuffix(key, SmallPictureSuffix);
            const smallPictureBlob = await ImageTools.cropToSquare(file, AvatarPictureSizes.small);
            await Storage.put(smallKey, smallPictureBlob, {
                contentType: file.type,
                progressCallback: (progress) => {progressCallback(progress.loaded / progress.total * 50)}
            });

            // Resize avatar picture to large size and upload it to S3
            const largeKey = this._addSuffix(key, LargePictureSuffix);
            const largePictureBlob = await ImageTools.cropToSquare(file, AvatarPictureSizes.large);
            await Storage.put(largeKey, largePictureBlob, {
                contentType: file.type,
                progressCallback: (progress) => {progressCallback(50 + progress.loaded / progress.total * 50)}
            });

            return smallKey;
        } catch (err) {
            console.warn('error: ', err)
        }
    }

    async _uploadImageAttachment(file, key, progressCallback) {
        try {
            // Create image thumbnail and upload it to S3
            const thumbnailKey = this._addSuffix(key, ThumbnailPictureSuffix);
            const thumbnailBlob = await ImageTools.scaleToFit(file, ThumbnailPictureDimensions);
            await Storage.put(thumbnailKey, thumbnailBlob, {
                contentType: file.type,
                progressCallback: (progress) => {progressCallback(progress.loaded / progress.total * 50)}
            });

            // Upload image to S3
            await Storage.put(key, file, {
                contentType: file.type,
                progressCallback: (progress) => {progressCallback(50 + progress.loaded / progress.total * 50)}
            });

            return thumbnailKey;
        } catch (err) {
            console.warn('error: ', err)
        }
    }

    async _deleteObject(key) {
        try {
            await Storage.remove(key);
        } catch (err) {
            console.log('error: ', err)
        }
    }

    _parseFileName(filename) {
        const separatorIndex = filename.lastIndexOf(".");
        if (separatorIndex === 0) {
            return {
                name: filename
            }
        }

        return {
            name: filename.substring(0, separatorIndex),
            extension: filename.substring(separatorIndex + 1)
        }
    }

    _addSuffix(filename, suffix) {
        const {name, extension} = this._parseFileName(filename);
        return extension ? `${name}${suffix}.${extension}` : `${name}${suffix}`;
    }

    _isSmallPictureKey(key) {
        return key.includes(SmallPictureSuffix);
    }

    _isLargePictureKey(key) {
        return key.includes(LargePictureSuffix);
    }

    _isThumbnailPicture(key) {
        return key.includes(ThumbnailPictureSuffix);
    }

    _removeSuffix(key) {
        const {name, extension} = this._parseFileName(key);
        const nameWithoutSuffix = key.substring(name.lastIndexOf("__") + 1)
        return extension ? `${nameWithoutSuffix}.${extension}` : `${nameWithoutSuffix}`;
    }
}