import React, { useContext } from 'react';
import {
    makeStyles, Container
} from "@material-ui/core";
import { Navigate, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { currentUserSelector } from "../../state/currentUser/selectors";
import Card from "../../components/Card";
// import WorkspaceWizard from "../../components/WorkspaceWizard";//OG convoluted
import { activeWorkspacesSelector } from "../../state/workspaces/selectors";
//import { addOrUpdateWorkspace } from "../../state/workspaces/actions";
import { toId } from "../../utils/apiUtils";
import Page from "../../components/Page/Page";
import { useTranslation } from "react-i18next";
import { ApiContext } from '../../contexts/ApiContext';

const useStyles = makeStyles((theme) => ({
    container: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
    },
    paper: {
        padding: [theme.spacing(2), theme.spacing(3), theme.spacing(3), theme.spacing(3)],
        backgroundColor: theme.palette.background.paper
    },
    wizard: {
    }
}));

export const MultiOnboarding = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const workspaces = useSelector(activeWorkspacesSelector());
    const user = useSelector(currentUserSelector());
    const navigate = useNavigate();
    const WorkspaceWizard = ({ onCompleted }) => {
        console.log("__Debug: Entering Workspace Wizard");
        const api = useContext(ApiContext);
        const [workspaceCount, setWorkspaceCount] = React.useState(0);
   
        const handleWorkspaceCountChanged = (value) => {
            setWorkspaceCount(value);
        };
        const getCurrentFormattedDateTime = () => {
            const now = new Date();
            const year = now.getFullYear();
            const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are zero-based
            const day = String(now.getDate()).padStart(2, '0');
            const hours = String(now.getHours()).padStart(2, '0');
            const minutes = String(now.getMinutes()).padStart(2, '0');
            const formattedDate = `${year}-${month}-${day}_${hours}:${minutes}`;
            return formattedDate;
        };

        const handleCompleted = async () => {
            console.log("__Debug: Entering WSW handleCompleted");

            if (workspaceCount === "") {
                return;
            }

            const workspaceCountInt = parseInt(workspaceCount, 10);
            if (isNaN(workspaceCountInt)) {
                return;
            }
            let last = "" 
            for (let i = 1; i <= workspaceCountInt; i++) {
                if (i === 1) {
                    console.log("__Debug: Entered WSW loop in handleCompleted");
                }
                try {
                    const workspace = await api.mutation.createWorkspace(`multi_create_testing-${getCurrentFormattedDateTime()}-${i}`, "standard", user.name, user.picture);
                    // dispatch(addOrUpdateWorkspace(workspace));
                    last = workspace;
                    console.log("__Debug: In WSW loop created", last);
                } catch (error) {
                    console.error(`Error creating workspace ${i}:`, error);
                }
            }
            console.log("__Debug: WSW handleCompleted completed.");
            //onCompleted(last);
        };

        return (
            <div className={classes.wizard}>
                <input
                    type="text"
                    placeholder={t("enter count of new workspaces to create")}
                    value={workspaceCount}
                    onChange={(e) => handleWorkspaceCountChanged(e.target.value)}
                />
                <button onClick={handleCompleted}>{t("Finish")}</button>
            </div>
        );
    };

    const handleOnCompleted = (workspace) => {
        navigate(`/portal/${toId(workspace.pk)}/home`);
    };

    return workspaces.length > 0 ? (
        <Navigate to={`/portal/${toId(workspaces[0].pk)}/home`} />
    ) : (
        <Page title={t("onboardingRouteTitle")}>
            <Container className={classes.container} maxWidth="sm">
                <Card className={classes.paper}>
                    <WorkspaceWizard onCompleted={handleOnCompleted} />
                </Card>
            </Container>
        </Page>
    )
}

