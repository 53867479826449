import React, {useContext, useState, useEffect, useCallback, useRef} from 'react';
import {ApiContext} from "../contexts/ApiContext";
import {ReactReduxContext, useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {addOrUpdateWorkspace, loadWorkspaces} from "../state/workspaces/actions";
import {allWorkspacesSelector} from "../state/workspaces/selectors";

// TODO: Return workspace directly, without having to go to redux to load workspace
// TODO: Rename
export const useFetchWorkspaces = (keys) => {
    const api = useContext(ApiContext);
    const { store } = useContext(ReactReduxContext);
    const dispatch = useDispatch();
    const isFetchingRef = useRef(false);
    const isLoadedRef = useRef(false);
    const [_, setRenderingTrigger] = useState(false);
    const workspaces = useSelector(allWorkspacesSelector());

    //console.log("useFetchWorkspaces, keys: %o", keys); //neat, but not that much helpful anymore.+)

    useEffect(() => {
        if (!keys || isFetchingRef.current) {
            return;
        }
        // Early exit if no keys were specified or workspaces with the specified keys are already loaded
        if (keys.length === 0 || keys.every(key => Boolean(workspaces.find(workspace => workspace.pk === key)))) {
            // Dispatch dummy action to force subscribed controls to rerender
            /*if (!isLoadedRef.current) {
                isLoadedRef.current = true; // This needs to be set before redux is called
                dispatch(loadWorkspaces([]));
            }*/

            return;
        }

        (async () => {
            console.log("fetching workspaces");
            isFetchingRef.current = true;
            //isLoadedRef.current = false;
            const workspaces = [];
            for (const key of keys) {
                let workspace = store.getState().workspaces[key];
                if (!Boolean(workspace?.name)) {
                    workspace = await api.query.getResource({pk: key, sk: key});
                }
                if (workspace) {
                    workspaces.push(workspace);
                }
            }

            //isLoadedRef.current = true; // This needs to be set before redux is called
            isFetchingRef.current = false;
            if (workspaces.length === 0) {
                // Calling loadWorkspaces redux action with empty array won't trigger rendering if there are no
                // workspaces loaded in redux, the new and old states would both be empty arrays
                setRenderingTrigger(prev => !prev);
            } else {
                dispatch(loadWorkspaces(workspaces));
            }
        })();
    }, [JSON.stringify(keys)]);

    return Boolean(keys) ? keys.every(key => Boolean(workspaces.find(workspace => workspace.pk === key))) : false;
}

