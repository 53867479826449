import React, { useContext, useState, useRef, useEffect } from 'react';
import {
    Box,
    Container, 
    Divider,
    Grid, 
    Link,
    makeStyles,
    Paper,
    Typography,
    Button,
    LinearProgress
} from '@material-ui/core';
import Card from "../../components/Card";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DeleteIcon from '@material-ui/icons/Delete';
import GetAppIcon from '@material-ui/icons/GetApp';
import {useTranslation} from "react-i18next";
import {STATIC_DOCUMENTS} from "../../constants/documents";
import WorkspacePage from "../../components/WorkspacePage/WorkspacePage";
import { ApiContext } from '../../contexts/ApiContext';
import { WorkspaceKeyContext } from '../../contexts/WorkspaceKeyContext';
import fetch from 'node-fetch'; // for blob-wise dwnl

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(3),
        minHeight: "100%"
    },
    title: {
        marginBottom: theme.spacing(5),
        whiteSpace: 'normal',
        wordBreak: 'break-word',
        wordWrap: 'break-word',
        hyphens: 'auto',
    },
    rightMargin: {
        marginRight: theme.spacing(0)
    },
    card: {
        flex: "1 1 auto",
        maxWidth: theme.spacing(43),
        height: theme.spacing(37),
        display: "flex",
        flexDirection: "column",
        padding: theme.spacing(3),
        '&>*:not(:last-child) ': {
            marginBottom: theme.spacing(2)
        }
    },
    cardContent: {
        flex: "1 1 auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        paddingBottom: theme.spacing(1),
        height: theme.spacing(21)
    },
    icon: {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main,
        borderRadius: 8,
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2)
    },
    extensionLabel: {
        display: "inline-block"
    },
    nameLabel: {
        overflow: "hidden",
        textOverflow: "ellipsis"
    },
    cardActions: {
        flex: "0 0 auto",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center"
    },
    footerLabelContainer: {
        flex: "1 1 auto"
    },
    uploadPaper: {
        padding: theme.spacing(3),
        textAlign: 'center',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.grey[50],
        border: `2px dashed ${theme.palette.grey[300]}`,
        cursor: 'pointer',
        '&:hover': {
            borderColor: theme.palette.primary.main,
            backgroundColor: theme.palette.grey[100],
        }
    },
    uploadIcon: {
        fontSize: 48,
        marginBottom: theme.spacing(2),
        color: theme.palette.grey[500]
    },
    hiddenInput: {
        display: 'none'
    },
    uploadProgress: {
        width: '100%',
        marginTop: theme.spacing(2)
    }
}));

const getFileExtension = (fileName) => {
    return fileName.split('.').pop();
}

const DocumentsView = () => {
    const classes = useStyles();
    const {t} = useTranslation();
    const api = useContext(ApiContext);
    const workspaceKey = useContext(WorkspaceKeyContext);
    const [uploadProgress, setUploadProgress] = useState({});
    const [uploadingFiles, setUploadingFiles] = useState([]);
    const [documents, setDocuments] = useState([]);
    const fileInputRef = useRef(null);
    const isMounted = useRef(true);

    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);

    useEffect(() => {
        const loadSignedUrls = async () => {
            const urls = {};
            for (const doc of documents) {
                if (doc.attachments && doc.attachments[0]) {
                    urls[doc.attachments[0]] = await api.storage.getSignedUrl(doc.attachments[0]);
                }
            }
            // setSignedUrls(urls);
        };
        // loadSignedUrls();
    }, [documents]);

    useEffect(() => {
        const loadDocuments = async () => {
            try {
                if (!workspaceKey) {
                    console.error('Workspace key is undefined');
                    return;
                } else {
                    // console.debug('Workspace key:', workspaceKey);
                }
                const result = await api.query.listResources({ 
                    // sk: workspaceKey, // causes,weirdly:  DocumentsView.js:146 Error loading documents: Error: GraphQL error: Variable 'sk' has an invalid value.
                    filter: {
                        workspace: { eq: workspaceKey },
                        pk: { beginsWith: 'document#' }
                    }
                });
                console.debug('Documents loaded:', result);
                if (isMounted.current && result) {
                    console.debug('Going to set documents:', result);
                    setDocuments(result);
                }
            } catch (error) {
                console.error('Error loading documents:', error);
            }
        };

        if (workspaceKey) {
            loadDocuments();
        }
    }, [workspaceKey, api.query]);

    const handleUpload = async (event) => {
        const files = Array.from(event.target.files);
        if (files.length === 0) return;
        for (const file of files) {
            if (file.size > 10485760) {
                alert('File size exceeds 10MB limit. Please upload smaller files or contact support.');
                return;
            }
        }

        setUploadingFiles(files);

        for (const file of files) {
            try {
                setUploadProgress(prev => ({
                    ...prev,
                    [file.name]: 0
                }));

                const key = await api.storage.uploadDocument(file, (progress) => {
                    if (isMounted.current) {
                        setUploadProgress(prev => ({
                            ...prev,
                            [file.name]: progress
                        }));
                    }
                });

                // Get the signed URL for the uploaded file
                const url = await api.storage.getSignedUrl(key);
                console.debug('File uploaded successfully:', url);

                if (isMounted.current) {
                    const documentResource = await api.mutation.createResource(
                        `document#${Date.now()}`,
                        workspaceKey,
                        {
                            name: file.name.length > 80 ? file.name.slice(0, 77) + '...' + getFileExtension(file.name) : file.name,
                            workspace: workspaceKey,
                            attachments: [key]
                        }
                    );

                    setDocuments(prev => [...prev, documentResource]);

                    setUploadProgress(prev => {
                        const newProgress = { ...prev };
                        delete newProgress[file.name];
                        return newProgress;
                    });
                }
            } catch (error) {
                console.error(`Error uploading ${file.name}:`, error);
                if (isMounted.current) {
                    setUploadProgress(prev => {
                        const newProgress = { ...prev };
                        delete newProgress[file.name];
                        return newProgress;
                    });
                }
            }
        }

        setUploadingFiles([]);
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };

    const handleDownload = async (event, docItem) => {
        /**
         * Fetches the signed URL for the document into a blob and triggers a download.
         * This could be solved by setting a response header Content-Disposition: attachment; filename=f"{doc}" instead of Content-Disposition: inline; filename=...
         *   but this is not something I know how to do, although I've met `content-disposition` in package-lock.js
         * The blob method was suggested by the https://chatgpt.com/c/677b931f-6afc-8008-8952-5f9cb4d6421e#o1-model
         * @param {Event} event - The click event.
         * @param {Object} docItem - The document item to download.
         */
        event.preventDefault();
        
        try {
            let downloadUrl;
            if (docItem.attachments && docItem.attachments[0]) {
                // For attachments, generate a signed URL
                const key = docItem.attachments[0];
                downloadUrl = await api.storage.getSignedUrl(key);
            } else if (docItem.url) {
                // For static documents, open in a new tab
                window.open(docItem.url, '_blank');
                return;
            } else {
                console.error('No download URL available');
                return;
            }

            // Make a fetch call to get the file as a Blob
            const response = await fetch(downloadUrl);
            if (!response.ok) throw new Error('Failed to download');

            const blob = await response.blob();

            // Create a Blob URL
            const blobUrl = window.URL.createObjectURL(blob);

            // Create an <a> element
            const link = document.createElement('a');
            link.style.display = 'none';
            link.href = blobUrl;
            link.download = docItem.name; // Force download

            document.body.appendChild(link);
            link.click();
            setTimeout(() => {
                document.body.removeChild(link);
                window.URL.revokeObjectURL(downloadUrl);
            }, 100);
        } catch (error) {
            console.error('Error handling download:', error);
        }
    };

    const handleDelete = async (document) => {
        try {
            // Delete the S3 object if it exists
            if (document.attachments && document.attachments[0]) {
                await api.storage.deleteAttachment(document.attachments[0]);
            }

            // Delete the DynamoDB record
            await api.mutation.deleteResource(document.pk, document.sk);

            // Update local state
            setDocuments(prev => prev.filter(doc => doc.pk !== document.pk));
        } catch (error) {
            console.error('Error deleting document:', error);
            // TODO: Add error notification
        }
    };

    const renderDocumentCard = (document) => (
        <Card className={classes.card}>
            <Link onClick={(e) => handleDownload(e, document)} href="#" download={document.name}>
                <div className={classes.cardContent}>
                    <div className={classes.icon}>
                        <Typography className={classes.extensionLabel} variant="h5" align="center">
                            {getFileExtension(document.name).toUpperCase()}
                        </Typography>
                    </div>
                    <Typography className={classes.title} variant="subtitle1">
                        {document.name}
                    </Typography>
                    <Typography className={classes.subtitle} variant="body2" color="textSecondary">
                        {document.type}
                    </Typography>
                </div>
            </Link>
            <div className={classes.cardActions}>
                <Box display="flex" alignItems="center">
                    <Typography variant="caption" color="textSecondary">
                        {document.size}
                    </Typography>
                </Box>
                {!STATIC_DOCUMENTS.some(staticDoc => staticDoc.name === document.name) && (
                    <Button
                        size="small"
                        style={{ marginRight: '2em' }}
                        color="secondary"
                        startIcon={<DeleteIcon />}
                        onClick={() => handleDelete(document)}
                    >
                        {t("Delete")}
                    </Button>
                )}
                <Button
                    size="small"
                    color="primary"
                    startIcon={<GetAppIcon />}
                    onClick={(e) => handleDownload(e, document)}
                >
                    {t("Download")}
                </Button>
            </div>
        </Card>
    );

    const renderUploadCard = () => (
        <Paper 
            className={classes.uploadPaper}
            component="label"
            elevation={0}
        >
            <input
                ref={fileInputRef}
                type="file"
                className={classes.hiddenInput}
                onChange={handleUpload}
                multiple
                accept=".pdf,.doc,.docx,.txt,.json,.yaml,.yml,.png,.jpg,.jpeg,.webp,.mp4"
            />
            <CloudUploadIcon className={classes.uploadIcon} />
            <Typography variant="h6" color="textSecondary" gutterBottom>
                {t("Upload Documents")}
            </Typography>
            <Typography variant="body2" color="textSecondary">
                {t("Click or drag files here")}
            </Typography>
            {uploadingFiles.map(uploadFile => (
                <Box key={uploadFile.name} width="100%" mt={2}>
                    <Typography variant="body2" align="left" gutterBottom>
                        {uploadFile.name}
                    </Typography>
                    <LinearProgress 
                        className={classes.uploadProgress}
                        variant="determinate" 
                        value={uploadProgress[uploadFile.name] || 0} 
                    />
                </Box>
            ))}
        </Paper>
    );

    return (
        <WorkspacePage>
            <Container maxWidth="lg" className={classes.container}>
                <Grid container alignItems="flex-start" spacing={2}>
                    {STATIC_DOCUMENTS.map(doc => (
                        <Grid key={doc.name} item xs={12} sm={6} md={3}>
                            {renderDocumentCard(doc)}
                        </Grid>
                    ))}
                    {documents.map(doc => (
                        <Grid key={doc.name} item xs={12} sm={6} md={3}>
                            {renderDocumentCard(doc)}
                        </Grid>
                    ))}
                    <Grid item xs={12} sm={6} md={3}>
                        {renderUploadCard()}
                    </Grid>
                </Grid>
            </Container>
        </WorkspacePage>
    );
};

export default DocumentsView;