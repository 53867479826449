import React, {useEffect, useMemo, useState} from "react";
import {ApiContext} from "../contexts/ApiContext";
import {useDispatch} from "react-redux";
import {loadOrUpdateCurrentUser} from "../state/currentUser/actions";
import {Auth} from "aws-amplify";
import AuthorizedAppSyncApi from "../api/AuthorizedAppSyncApi";
import {AuthContext} from "../contexts/AuthContext";
import {Routes} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {isMembership, isUser, toId} from "../utils/apiUtils";
import {addMember} from "../state/members/actions";
import Loader from "../components/Loader";
import { alpha } from "@material-ui/core/styles"; // Updated import

// TODO: Rename to user routes
const AuthorizedRoutes = ({children, ...rest}) => {
    const dispatch = useDispatch();
    const { i18n } = useTranslation();
    //const api = useContext(ApiContext);
    //const notificationContext = useContext(NotificationContext);
    const [isLoading, setIsLoading] = useState(true);
    const [authenticatedUser, setAuthenticatedUser] = useState();

    const api = useMemo(() => {
        return new AuthorizedAppSyncApi();
    }, []);

    useEffect(() => {
        signIn();
    }, []);

    const signIn = async () => {
        setIsLoading(true);
        try {
            const currentUser = await Auth.currentAuthenticatedUser({bypassCache: false});
            console.log("SIGN IN")

            if (!authenticatedUser) {
                setAuthenticatedUser(currentUser);

                const resources = await api.query.listCurrentUserResources();
                const userInfo = resources.find(res => isUser(res));
                const memberships = resources.filter(res => isMembership(res));
                const workspaces = memberships.map(res => ({pk: res.sk, sk: res.sk}));

                // Sometimes the current JWT token may not include all workspaces the user is member of in the
                // cognito:groups. This can happen if the user joins workspace on a different device. In that case
                // only application on that device would receive new token. The current application would keep using
                // the old token, since it is not expired. This would make the current application crash since it
                // wouldn't have rights to access this new workspace.
                const authenticatedGroups = currentUser.signInUserSession.accessToken.payload["cognito:groups"] ?? [];
                const isOutdatedToken = workspaces.some(workspace => !authenticatedGroups.includes(toId(workspace.pk)));
                if (isOutdatedToken) {
                    console.log("TOKEN outdated");
                    await refreshToken();
                }

                dispatch(loadOrUpdateCurrentUser(userInfo));
                //dispatch(loadWorkspaces(workspaces));
                for (const workspace of workspaces) {
                    dispatch(addMember(workspace.pk, memberships.find(membership => membership.sk === workspace.pk)));
                }

                if (userInfo.locale) {
                    await i18n.changeLanguage(userInfo.locale);
                }
            }
        } catch (err) {
            console.log(err);
            setAuthenticatedUser(null);
        }
        setIsLoading(false);
    }

    const signOut = async () => {
        const userKey = `user#${authenticatedUser.attributes.sub}`;
        //notificationContext.unsubscribe();
        api.mutation.updateResource(userKey, userKey, {pushSubscription: null});
        await Auth.signOut();
        setAuthenticatedUser(null);
    }

    const refreshToken = async () => {
        const cognitoUser = await Auth.currentAuthenticatedUser();
        const { refreshToken } = cognitoUser.getSignInUserSession();
        await new Promise((resolve, reject) => {
            cognitoUser.refreshSession(refreshToken, (err, session) => {
                if (err) return reject(err)
                resolve(session);
            });
        });
    }

    return  (
        <ApiContext.Provider value={api}>
            <AuthContext.Provider value={{ isAuthenticated: Boolean(authenticatedUser), signIn, signOut, refreshToken }}>
                {isLoading ? (
                    <Loader/>
                ) : (
                    <Routes {...rest}>
                        {children}
                    </Routes>
                )}
            </AuthContext.Provider>
        </ApiContext.Provider>

    )
}

export default AuthorizedRoutes;